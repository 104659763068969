<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}
</style>

<template>
  <el-dialog top="6vh" v-if="m" :title="this.id == 0 ? '数据增加' : '数据修改'" :visible.sync="isShow" width="800px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <!-- 参数栏 -->
        <br />
        <el-form size="medium" :inline="true" label-width="200px">
          <el-form-item label="服务商名称">
            <el-input v-model="m.providerName" style="width: 300px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="业务域名">
            <el-input v-model="m.domain" style="width: 300px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="服务商商户号">
            <el-input v-model="m.providerId" style="width: 300px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="证书序列号">
            <el-input v-model="m.providerSerialNumber" style="width: 300px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="v3key">
            <el-input v-model="m.providerV3Key" style="width: 300px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="私钥">
            <el-input :rows="5" type="textarea" v-model="m.providerPrivateKey" style="width: 500px"></el-input>
          </el-form-item>
          <br />

          <!-- <el-form-item label="支付公众号:">
            <el-select v-el-select-loadmore="loadmore" v-model="m.providerAppid" @change="changeValue($event,1)" @click.native="getOfficailAccount()">
              <el-option v-for="item in officailselectList" :key="item.officailAppid" :label="item.officailName" :value="item.officailAppid">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="分账公众号:">
            <el-select v-el-select-loadmore="loadmore" v-model="m.providerMiniAppid" @change="changeValue($event,2)" @click.native="getOfficailAccount()">
              <el-option v-for="item in officailselectList" :key="item.officailAppid" :label="item.officailName" :value="item.officailAppid">
              </el-option>
            </el-select>
          </el-form-item> -->
          <br />

          <!-- <el-form-item label="微信公众号appid">
            <el-input v-model="m.providerAppid" style="width: 300px"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="微信公众号密钥">
            <el-input v-model="m.providerAppSecret" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="分账公众号appid">
            <el-input v-model="m.providerMiniAppid" style="width: 300px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="分账公众号密钥">
            <el-input v-model="m.providerMiniSecret" style="width: 300px"></el-input>
          </el-form-item> -->
          <el-form-item label="操作密码">
            <el-input v-model="m.providerPassWord" style="width: 300px" show-password></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      id: 0,
      m: {
        providerName: "",
        domain: "",
        providerId: "",
        providerV3Key: "",
        providerSerialNumber: "",
        providerPrivateKey: "",
        providerAppid: "wx1111111111111119",
        providerMiniSecret: "",
        providerMiniAppid: "",
        providerPassWord: "",
        providerAppSecret: "TEST1111111111111111",
      },
      p: {
        // 查询参数
        page: 1,
        limit: 20,
      },
      officailselectList: [],
    };
  },
  methods: {
    // 打开
    open: function (data) {
      this.id = data.id;
      this.isShow = true;
      this.getOfficailAccount();
      if (this.id == 0 || data == 0) {
        // 如果是添加
        console.log("数据添加");
        this.id = 0;
      } else {
        // id 非 0 代表 是要修改 先查询一下，把旧值显示在input中
        console.log("数据修改");
        this.getData();
        console.log(this.m);
      }
    },
    changeValue: function (value, type) {
      var obj = {};
      console.log(value);
      //使用find()方法在下拉数据中根据value绑定的数据查找对象。
      obj = this.officailselectList.find(function (item) {
        return item.officailAppid === value;
      })
      if (type == 1) {
        this.m.providerAppSecret = obj.officailAppSecret;
      } else {
        this.m.providerMiniSecret = obj.officailAppSecret;
      }
      console.log(obj.officailAppSecret);
    },
    // 表单验证
    submit_check: function () {
      return "ok";
    },
    // 提交
    ok: function () {
      // 表单验证
      if (this.submit_check() != "ok") {
        return;
      }
      // 开始增加或修改
      let self = this;
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      if (this.id == 0) {
        // id == 0 为增加
        this.sa.ajax(
          this.sa.cfg.provider_api_url + "/provider/save/",
          this.m,
          function () {
            self.sa.alert("添加成功", function () {
              self.$parent.f5(); // 父视图刷新
              self.isShow = false;
              self.claerData();
              self.claerRedisData();
            });
          },
          defaultCfg
        );
      } else {
        // id != 0 为修改
        this.sa.ajax(
          this.sa.cfg.provider_api_url + "/provider/edit/" + this.id,
          this.m,
          function () {
            self.sa.alert(
              "修改成功",
              function () {
                self.$parent.f5(); // 父视图刷新
                self.isShow = false;
                self.claerData();
                self.claerRedisData();
              },
              defaultCfg
            );
          },
          defaultCfg
        );

      }
    },
    getOfficailAccount: function () {
      var defaultCfg = {
        type: "post", // 默认请求类型
        isBody: true, //是否是请求体请求
        msg: null,
      };
      if (this.sa_admin.role == 2) {
        this.sa.ajax(
          "/v1/officail/list",
          this.p,
          function (res) {
            this.officailselectList = res.data.records; // 数据
          }.bind(this),
          defaultCfg
        );
      } else {
        this.p.mchId = this.sa_admin.mchId;
        this.sa.ajax(
          "/v1/officail/list",
          this.p,
          function (res) {
            this.officailselectList = res.data.records; // 数据
          }.bind(this),
          defaultCfg
        );
      }
    },
    claerRedisData() {
      var defaultCfg = {
        type: "get",
      };
      this.sa.ajax(
        "/v2/admin/cleanProviderInfoAll",
        this.m,
        function () {
        },
        defaultCfg
      );
    },
    claerData() {
      this.m.providerName = "";
      this.m.providerAppid = "";
      this.m.providerId = "";
      this.m.providerPassWord = "";
      this.m.providerAppSecret = "";
      this.m.providerSerialNumber = "";
      this.m.providerPrivateKey = "";
      this.m.providerV3Key = "";
      this.m.providerMiniSecret = "";
      this.m.providerMiniAppid = "";
    },
    loadmore() {
      this.p.page = this.p.page++;
      this.getOfficailAccount();
    },
    getData() {
      let self = this;
      var defaultCfg = {
        type: "get",
      };
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/provider/info/" + this.id,
        function (res) {
          self.m.providerName = res.data.providerName;
          self.m.domain = res.data.domain;
          self.m.providerAppid = res.data.providerAppid;
          self.m.providerAppSecret = res.data.providerAppSecret;
          self.m.providerId = res.data.providerId;
          self.m.providerPassWord = res.data.providerPassWord;
          self.m.providerPrivateKey = res.data.providerPrivateKey;
          self.m.providerV3Key = res.data.providerV3Key;
          self.m.providerSerialNumber = res.data.providerSerialNumber;
          self.m.providerMiniSecret = res.data.providerMiniSecret;
          self.m.providerMiniAppid = res.data.providerMiniAppid;
        },
        defaultCfg
      );
    },
  },
  created: function () { },
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
};
</script>
