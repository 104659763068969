<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">电商通服务商管理</div>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-button type="primary" icon="el-icon-plus" v-if="this.sa_admin.role == 2" size="mini" @click="add()">电商通服务商</el-button>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="dataList" size="mini">
        <el-table-column label="id" prop="id" width="50px"> </el-table-column>
        <el-table-column label="服务商名称" prop="providerName" width="170px">
        </el-table-column>
        <el-table-column label="商户号" prop="providerId" width="100px"></el-table-column>
        <el-table-column label="业务域名" prop="domain" width="200px"></el-table-column>
        <el-table-column label="创建时间" prop="createdTime" width="150px">
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="s">
            <el-button class="c-btn" type="success" icon="el-icon-edit" @click="update(s.row)">编辑</el-button>
            <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <ServerAdd ref="server-add"></ServerAdd>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ServerAdd from "./server-add";

export default {
  components: { ServerAdd },
  data() {
    return {
      p: {
        page: 1,
        limit: 10,
      },
      title: "添加服务商",
      userDialogVisible: false,
      dataCount: 0,
      dataList: [],
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        type: "get", // 默认请求类型
      };
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/provider/list",
        this.p,
        function (res) {
          this.dataList = res.data.records; // 数据
          this.dataCount = res.data.total; // 分页
        }.bind(this),
        defaultCfg
      );
    },
    // 删除
    del: function (data) {
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/provider/delete/" + data.id,
        function () {
          this.f5();
        }.bind(this)
      );
    },
    add: function () {
      this.$refs["server-add"].open(0);
    },
    update: function (data) {
      this.$refs["server-add"].open(data);
    },
  },
  created: function () {
    this.f5();
  },
};
</script>

<style>
</style>
